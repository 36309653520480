
.customer-analysis-content {
    min-height: calc(100vh - 120px);
    .header {
        font-size: 18px;
        font-family: PingFang SC;
        font-weight: bold;
        color: #343434;
        margin: 20px 0;
    }
    .customer-analysis-tab {
        min-height: calc(100vh - 246px);
        background-color: #fff;
        position: relative;
        .end-index-switch {
            position: absolute;
            top: 0;
            right: 0;
            height: 50px;
            display: flex;
            padding-right: 10px;
            justify-content: flex-end;
            align-items: center;
        }
        :deep(.customer-analysis-el-tabs) {
            min-height: calc(100vh - 246px);
            background-color: #fff;
            .el-tabs__item {
                margin-top: 10px;
                padding-left: 20px;
                font-size: 16px;
                font-family: PingFang SC;
                font-weight: 500;
                color: #666666;
                &.is-active {
                    color: #FD4446;
                }
            }
            .el-tabs__active-bar {
                background-color: #FD4446;
            }
            .el-tabs__content {
                /*height: calc(100% - 65px);*/
                .el-tab-pane {
                    min-height: calc(100vh - 267px);
                }
            }
            .el-tabs__nav-wrap::after {
                background-color: #fff;
            }
        }
    }
}
.analysis-content {
    padding: 20px 30px 20px 40px;
    background: #F7F9FB;
    box-sizing: border-box;
}
.right-wrapper {
    display: flex;
    flex-direction: column;
    .right-header {
        height: 80px;
        background: #fff;
        border-bottom: 1px solid #D1D2DB;
        box-sizing: border-box;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        .header-tab {
            height: 100%;
            position: absolute;
            left: 30px;
            display: flex;
            align-items: center;
            font-weight: 500;
            color: #333333;
            .activeSelect {
                span {
                    display: block;
                    height: 100%;
                    color: #FD4446 !important;
                    border-bottom: 2px solid #FD4446 !important;
                }
            }
            .item-tab {
                height: 100%;
                margin-right: 25px;
                cursor: pointer;
                line-height: 81px;
                border-bottom: 2px solid transparent;
                font-size: 20px;
                span {
                    color: #333333;
                    &:hover {
                        color: #FD4446;
                    }
                }
            }
        }
    }
}
::v-deep .el-input__inner{
    width: 80px;
}
